.container {
  display: flex;
  width: 40%;
  padding: 36px;
}

.image {
  width: 400px;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.textContainer {
  display: flex;
  flex-direction: column;
  padding: 36px;
  align-content: center;
  justify-content: center;
}
.text {
  margin: 6px 0;
}
.header {
  margin: 6px 0;
}
