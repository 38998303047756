$primary-color: #ecf0f1;
$primary-color: #f1f2f6;
$secondary-color: #61dafb;
$box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

$white: #f1f2f6;
$darkest-gray: #2f3542;
$dark-gray: #57606f;
$gray: #a4b0be;
$light-gray: #95a5a6;
$lightest-gray: #dbdfe2;
$samaritan: #3c6382;
$orange: #f8c291;
$beige: #aaa69d;
$falu-red: #9a2d2d;
$falu-blue: #0d529f;
$light-green: #d4ffff;
$light-brown: #cbc4ae;
$light-blue: #b5d8ff;
$light-red: #f6e0d8;
$light-red2: #c9c7ca;
$light-red3: #e7c7ca;

$light-transparent: rgba(223, 223, 223, 0.111);
$dark-transparent: rgba(61, 72, 82, 0.8);
$transparent: rgba(170, 166, 157, 0.6);

$breakpoints: (
  "small": (
    min-width: 426px,
  ),
  "medium": (
    min-width: 768px,
  ),
  "large": (
    min-width: 1023px,
  ),
  "huge": (
    min-width: 1825px,
  ),
);

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == "string",
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}
